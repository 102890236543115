import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import Link from 'next/link';
import NotFoundIcon from '~/assets/icons/jsx/404';
import Button from '~/components/Button';

import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import RESOURCES from '~/resources';
import { ROUTES } from '~/data/routes';
import {
  notFound,
  notFoundArrowStyled,
  notFoundContainer,
  notFoundGhost,
  notFoundText,
  notFoundToLinks,
  notFoundWrapper,
} from '~/styles/pages/404/useStyles';

import ArrowLeft from '~/assets/icons/jsx/ArrowLeft';

const Custom404: React.FC = () => {
  const events = useEvents();

  useEffect(() => {
    events.sendEvent(
      {
        category: ECategoryEvent.NOT_FOUND,
        action: EActionEvent.NOT_FOUND_PAGE_LOAD,
      },
      { referrer: document.referrer },
    );
  }, [events]);

  return (
    <Box sx={notFoundContainer}>
      <Box sx={notFoundWrapper}>
        <Box sx={notFound}>
          <NotFoundIcon />
          <Box sx={notFoundGhost}></Box>
        </Box>
        <Typography sx={notFoundText}>{RESOURCES.NOT_FOUND}</Typography>

        <Box sx={notFoundToLinks}>
          <Link href={ROUTES.RESULT_PAGE}>
            {RESOURCES.TO_NEW_CAR} <ArrowLeft Component={notFoundArrowStyled} />
          </Link>
          <Link href={ROUTES.RESULT_PAGE_USED_CAR}>
            {RESOURCES.TO_USED_CAR}{' '}
            <ArrowLeft Component={notFoundArrowStyled} />
          </Link>
          <Link href={ROUTES.RESULT_PAGE_LEASE}>
            {RESOURCES.TO_LEASE_CAR}{' '}
            <ArrowLeft Component={notFoundArrowStyled} />
          </Link>
        </Box>
        <Link href={ROUTES.HOMEPAGE} passHref>
          <Button
            label={RESOURCES.TO_HOME_PAGE_BTN}
            type={BUTTON_TYPES.MAIN}
            size={BUTTON_SIZES.MEDIUM}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default Custom404;
