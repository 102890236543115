import { SxProps, Theme, styled } from '@mui/material';
import ghost from '~/assets/images/ghost.png';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const notFoundContainer = {
  padding: '96px 20px 26px',

  [theme.breakpoints.up('md')]: {
    padding: '126px 70px 62px',
  },
};
export const notFoundWrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.gray.light,
  padding: '120px 0 50px',

  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: '1290px',
    padding: '220px 0 100px',
  },
};

export const notFoundText = {
  textAlign: 'center',
  whiteSpace: 'break-spaces',
  lineHeight: '14px',
};

export const notFound: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '40px',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    paddingBottom: '50px',
  },
  '& svg': {
    position: 'relative',
    zIndex: '0',
  },
};

export const notFoundGhost = {
  position: 'absolute',
  top: '-9px',
  left: 'auto',
  right: 'auto',
  background: `url(${ghost.src}) no-repeat center center`,
  backgroundSize: 'contain',
  height: '100%',
  width: '46%',
  marginLeft: '6px',
  [theme.breakpoints.up('md')]: {
    top: '9px',
    width: '43%',
  },
};

export const notFoundToLinks: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
  margin: '62px auto 35px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '34px',
    margin: '24px auto 35px',
  },

  '& a': {
    display: 'flex',
    gap: '8px',
    color: COLORS.primary.dark2,
    fontSize: '16px',
    textDecoration: 'none',
  },
};
export const notFoundArrowStyled = styled('svg')(() => ({
  height: '20px',
  '& path': {
    stroke: COLORS.primary.dark2,
  },
}));
