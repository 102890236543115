import React from 'react';
import { ImageFile } from '~/data/types/images';

const NotFoundIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="257"
      height="109"
      viewBox="0 0 257 109"
    >
      <path
        d="M82.4934 86.9614H70.7714V107.461H42.351V86.9614H-0.00317383V66.0211L44.2309 1.54688H70.7714V65.5802H82.4934V86.9614ZM42.351 65.5802V53.2365C42.351 50.922 42.4615 47.836 42.5721 43.8684C42.7933 39.9008 42.9039 37.9169 43.0145 37.6965H42.2404C40.5816 41.4437 38.7016 44.8603 36.6005 48.1667L24.8785 65.5802H42.351Z"
        fill="#D8D8D8"
      />
      <path
        d="M166.759 54.6653C166.759 73.4014 163.552 87.178 157.359 95.8847C151.056 104.592 141.435 109 128.386 109C115.668 109 106.158 104.481 99.6336 95.3337C93.2197 86.1861 90.0127 72.6299 90.0127 54.6653C90.0127 35.819 93.1091 22.0425 99.523 13.2255C105.826 4.40849 115.447 0 128.496 0C141.103 0 150.724 4.62892 157.138 13.7765C163.552 22.9242 166.759 36.4803 166.759 54.6653ZM118.544 54.6653C118.544 66.6785 119.318 74.9444 120.755 79.3529C122.304 83.8716 124.736 86.0758 128.275 86.0758C131.814 86.0758 134.357 83.7614 135.906 79.1325C137.343 74.5035 138.117 66.3478 138.117 54.6653C138.117 42.8726 137.343 34.7169 135.906 29.9778C134.357 25.2386 131.924 22.9242 128.386 22.9242C124.847 22.9242 122.304 25.2386 120.866 29.7573C119.318 34.276 118.544 42.542 118.544 54.6653Z"
        fill="#D8D8D8"
      />
      <path
        d="M256.997 86.9614H245.275V107.461H216.854V86.9614H174.5V66.0211L218.734 1.54688H245.275V65.5802H256.997V86.9614ZM216.965 65.5802V53.2365C216.965 50.922 217.076 47.836 217.186 43.8684C217.407 39.9008 217.518 37.9169 217.628 37.6965H216.854C215.196 41.4437 213.316 44.8603 211.215 48.1667L199.382 65.6904H216.965V65.5802Z"
        fill="#D8D8D8"
      />
    </Component>
  );
};

export default NotFoundIcon;
